



























































































import WidgetMixins from '../../../../mixins/WidgetMixins';
import Component, { mixins } from 'vue-class-component';
import { loadWidget } from '@/utils/helpers';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { PayloadState } from '@/types/types';
const paymentDetailsModule = namespace('paymentDetails');
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { numeric, required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Field is required',
});
extend('numeric', {
  ...numeric,
  message: 'Invalid mobile money number provided',
});

@Component({
  name: 'AddPaymentMethod',
  components: {
    ValidationProvider,
    ValidationObserver,
    SkeletonPreloader: loadWidget('widgets/SkeletonPreloader'),
  },
})
export default class GetLink extends mixins(WidgetMixins) {
  @Getter('getResetFormState') resetFormState!: boolean;
  @paymentDetailsModule.Getter('getResetFormValues') resetFormValues!: {
    mobileMoneyNumber: string;
    isDefault: boolean;
  };

  @Prop({ default: false, required: true }) state!: boolean;
  @Prop({ default: false }) dialogLoading!: boolean;
  @Prop({ default: false }) loading!: boolean; // button loading
  @Prop({ default: false }) link!: string;

  payment: { mobileMoneyNumber: string; isDefault: boolean } = {
    mobileMoneyNumber: '',
    isDefault: false,
  };

  @Watch('resetFormState')
  onFormChange(payload: boolean): void {
    if (payload) {
      this.payment = { ...this.resetFormValues };
    } else this.$store.dispatch('resetFormValues', false, { root: true });
  }

  @Emit('payment')
  addPayment(): void {
    console.log('lds');
  }

  @Emit('actions')
  close(): PayloadState {
    return {
      idx: 'details',
      state: false,
    };
  }
}
